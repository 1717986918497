import React from 'react';
import FadeInSection from './FadeInSection'; // Import the FadeInSection component

const Impressum = () => {
    return (
        <FadeInSection>
            <div style={styles.container}>
                <h1 style={styles.title}>Impressum</h1>
                <div style={styles.section}>
                    <h2 style={styles.subtitle}>Angaben gemäß § 5 TMG:</h2>
                    <p style={styles.text}>
                        Andre Marcel Stierl
                        <br />
                        Beyerstieg 12-16
                        <br />
                        21107 Hamburg
                    </p>
                </div>
                <div style={styles.section}>
                    <h2 style={styles.subtitle}>Kontakt / Contact:</h2>
                    <p style={styles.text}>
                        Telefon: auf anfrage
                        <br />
                        E-Mail: andre@rasun.de
                    </p>
                </div>
                <div style={styles.section}>
                    <h2 style={styles.subtitle}>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
                    <p style={styles.text}>
                        Andre Marcel Stierl
                        <br />
                        Beyerstieg 12-16
                        <br />
                        21107 Hamburg
                    </p>
                </div>
                <div style={styles.footer}>
                    <p style={styles.footerText}>© 2024 Rasun. All rights reserved.</p>
                </div>
            </div>
        </FadeInSection>
    );
};

const styles = {
    container: {
        backgroundColor: '#f4f4f4',
        color: '#333',
        padding: '40px',
        margin: '20px auto',
        maxWidth: '800px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        textAlign: 'center',
        fontSize: '2.5rem',
        marginBottom: '20px',
        color: '#222',
    },
    subtitle: {
        fontSize: '1.5rem',
        margin: '20px 0 10px',
        color: '#444',
    },
    text: {
        fontSize: '1rem',
        color: '#555',
    },
    section: {
        marginBottom: '20px',
    },
    footer: {
        backgroundColor: '#2c2e3a', // Navy background
        color: '#fff',
        textAlign: 'center',
        padding: '20px',
        borderRadius: '0 0 10px 10px',
        marginTop: '40px',
    },
    footerText: {
        fontSize: '0.9rem',
        margin: 0,
    },
};

export default Impressum;
