import React from "react";
import { Sidenav } from "rsuite";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import "../styles/SidebarNav.css";
import "react-typist/dist/Typist.css";
import FadeInSection from "./FadeInSection";

const isMobile = window.innerWidth < 600;

class SidebarNav extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }

  render() {
    const { expanded } = this.state;
    const { onNavigate, resetImpressum } = this.props; // Include resetImpressum for other links

    const links = [
      <a href="#intro" onClick={resetImpressum}>/home</a>,
      <a href="#about" onClick={resetImpressum}>/about</a>,
      <a href="#experience" onClick={resetImpressum}>/experience</a>,
      <a href="#projects" onClick={resetImpressum}>/projects</a>,
      <a onClick={() => onNavigate("impressum")}>/impress</a>, // Impressum link stays dynamic
    ];

    return (
        <div className="sidebar-nav">
          {!isMobile && (
              <Sidenav
                  expanded={expanded}
                  defaultOpenKeys={["3", "4"]}
                  activeKey={this.state.activeKey}
                  onSelect={this.handleSelect}
                  appearance={"subtle"}
              >
                <Sidenav.Body>
                  <div className="sidebar-links">
                    {links.map((link, i) => (
                        <FadeInSection key={i} delay={`${i + 1}00ms`}>
                          <div>{link}</div>
                        </FadeInSection>
                    ))}
                  </div>
                </Sidenav.Body>
              </Sidenav>
          )}
          <div className="sidebar-logos">
            <a href="mailto:andre@rasun.de">
              <EmailRoundedIcon style={{ fontSize: 20 }}></EmailRoundedIcon>
            </a>
          </div>
        </div>
    );
  }
}

export default SidebarNav;
