import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const one = (
        <p>
            Welcome to Rasun! We are a passionate duo of <b>Full-Stack Developers</b> from Germany, combining our expertise to craft modern, high-performing websites and applications. Our skill set covers <b>front-end</b> and <b>back-end development</b>, <b>DevOps</b>, and <b>database management</b>
        </p>
    );
    const two = (
        <p>

        </p>
    );
    const three = (
        <p>
          <b>Want to chat?</b> Shoot me a message at{" "}
          <a href="mailto:andre@rasun.de">
            andre@rasun.de
          </a>{" "}
          and let's talk.
        </p>
    );
    const desc_items = [one, two];

    const tech_stack = [
      "Javascript",
      "Typescript",
      "Python",
      "React",
      "NextJS",
      "React Native",
      "Docker",
      "Node.js",
      "SQL",
      "Playwright",
    ];

    const tech_items = tech_stack.map(stack => <li>{stack}</li>);

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about us</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {[one]}
              {<p>Here are some technologies I have been working with: <br />
              (some more than others)
              </p> }
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
              {[two]}
            </div>
            <div className="about-image">
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
