import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    Rasun: {
      jobTitle: "Developers @ Rasun",
      duration: "Present",
      desc: [
        "Designing and developing modern websites and systems for clients, ensuring high performance and user-centric design.",
        "Specializing in full-stack development, we deliver tailored solutions that align with business goals and enhance online presence.",
        "Collaborate closely with clients to understand their needs and transform ideas into functional, visually appealing digital experiences."
      ]
    },
    StudyAbroad: {
      jobTitle: "Web Development Collaborator @ Finland & Taiwan",
      duration: "MARCH 2023 - APRIL 2023",
      desc: [
        "Worked with companies in Finland and Taiwan to revamp their websites, making them more appealing and user-friendly.",
        "Assisted in building digital services to improve client engagement and streamline operations, utilizing React and other modern technologies.",
        "Gained valuable insights into international collaboration and cultural approaches to problem-solving in tech."
      ]
    },
    BachelorStudies: {
      jobTitle: "Computer Science Students @ Hamburg",
      duration: "AUG 2021 - Present",
      desc: [
        "Pursuing a dual-study program in Computer Science, combining academic knowledge with hands-on development experience.",
        "Gained expertise in software development, system architecture, and innovative technologies, applying this knowledge to real-world projects.",
        "Focus on continuous learning and staying ahead of industry trends to bring cutting-edge solutions to clients."
      ]
    }
  };



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
