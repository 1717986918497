import React, { useState } from "react";
import SidebarNav from "./components/SidebarNav";
import Intro from "./components/Intro";
import Experience from "./components/Experience";
import About from "./components/About";
import Projects from "./components/Projects";
import Credits from "./components/Credits";
import Impressum from "./components/Impressum";
import "./App.css";
import "./styles/Global.css";
import "rsuite/dist/styles/rsuite-default.css";

function App() {
    const [showImpressum, setShowImpressum] = useState(false); // State to toggle Impressum

    const resetImpressum = () => setShowImpressum(false); // Reset Impressum state

    return (
        <div className="App" style={{ backgroundColor: '#2c2e3a', minHeight: '100vh', padding: '20px' }}>
            <div id="content">
                {showImpressum ? (
                    <Impressum onBack={resetImpressum} />
                ) : (
                    <>
                        <Intro />
                        <About />
                        <Experience />
                        <Credits />
                    </>
                )}
            </div>
            {/* Pass both onNavigate and resetImpressum to SidebarNav */}
            <SidebarNav
                onNavigate={() => setShowImpressum(true)}
                resetImpressum={resetImpressum}
            />
        </div>
    );
}

export default App;
