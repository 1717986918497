import React from "react";
import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import MomoModel from "./ModelModel";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";
import FractalTree from "./FractalTree";
import {color} from "three/examples/jsm/libs/dat.gui.module";
class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    return (
      <div id="intro">
        <FractalTree></FractalTree>
        {/*<FractalTree></FractalTree>*/}
        <Typist avgTypingDelay={120}>
          <span className="intro-title">
            {"hi, "}
            <span className="intro-name">{"Andre"}</span>
            {" here."}
          </span>
        </Typist>
        <FadeInSection>
          <div className="intro-subtitle">I create stuff sometimes.</div>
          <div className="intro-desc">
            <p>
              We are software developers from Germany with a passion for <b>web development</b>, <b>artificial
              intelligence</b>, <b>software architecture</b>, and <b>design</b>. Our expertise spans the entire
              development lifecycle, from creating intuitive user interfaces to building robust backend systems.<br/>
            </p>
          </div>
          <a
              href="mailto:andre@rasun.de"
              className="intro-contact"
          >
            <EmailRoundedIcon></EmailRoundedIcon>
            {"  " + "Contact us !"}
          </a>
        </FadeInSection>
      </div>
    );
  }
}

export default Intro;
